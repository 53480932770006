import randomcolor from 'randomcolor';
import ClipboardJS from 'clipboard';
import shipharbor from 'shipharbor';

let router = new shipharbor();

let isHexColor = (sNum) => {
  return (typeof sNum === "string") && sNum.length === 6 
         && ! isNaN( parseInt(sNum, 16) );
}

let lightOrDark = (color) => {
  let r, g, b, hsp;
  
  if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      
      r = color[1];
      g = color[2];
      b = color[3];
  } else {
      color = +("0x" + color.slice(1).replace( 
      color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
  }
  
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  if (hsp > 127.5) {
      return 'light';
  } else {
      return 'dark';
  }
}

let color = '';

let choose = () => {
  let color = randomcolor().slice(1);

  history.pushState({}, 'Color by Tobias Herber', '/' + color);

  let match = router.match(location.pathname);
  match.handler(match.params);
}

document.querySelector('body').addEventListener('click', () => {
  choose();
})

new ClipboardJS('body', {
  text: () => {
    return color;
  }
});

router.add('/', choose);

router.add('/:hex', (params) => {
  if (isHexColor(params.hex)) {
    document.querySelector('body').style.background = '#' + params.hex;
    document.querySelector('body').style.color = lightOrDark(params.hex) == 'light' ? 'black' : 'white';
    document.querySelector('.by a').style.color = lightOrDark(params.hex) == 'light' ? 'black' : 'white';
    document.querySelector('.hex').textContent = '#' + params.hex;

    color = '#' + params.hex;
  } else {
    notFound();
  }
});

let notFound = () => {
  document.querySelector('body').style.background = 'white';
  document.querySelector('body').style.color = 'black';
  document.querySelector('.by a').style.color = 'black';
  document.querySelector('.hex').textContent = 'Not found.'
};

router.add('/*', notFound);

let match = router.match(location.pathname);
match.handler(match.params);
